<template>
  <Loading v-if="initLoading" />
  <div style="padding-bottom: 50px" v-else>
    <div class="top">
      <img src="../../../static/image/left.png" @click="back" alt="" />
      <div class="order_text">
        <!-- {{ anyCollectionDetail.goods.name }} -->
        藏品详情
      </div>
    </div>

    <!-- 图片 -->

    <div class="details_box">
      <img
        v-if="!anyCollectionDetail.goods.video"
        :src="anyCollectionDetail.goods.showPic"
        alt=""
      />
      <div v-else class="videos">
        <video
          :src="anyCollectionDetail.goods.video"
          ref="video"
          controls="controls"
        />
        <div
          style="position: relative; z-index: 10"
          :style="{ zIndex: isPlayVideo ? 8 : 10 }"
          v-if="anyCollectionDetail.goods.showPic"
        >
          <img class="bg" :src="anyCollectionDetail.goods.showPic" alt="" />
          <div class="play" @click="playVideo">
            <img src="../../assets/images/play.svg" alt="" />
          </div>
        </div>
      </div>
      <div
        v-if="anyCollectionDetail.isallow || anyCollectionDetail.chartInfo"
        class="is_allow"
        @click="showFortune"
      >
        <img src="../../assets/images/look.png" alt="" />
        <div>
          <p>查看</p>
          <p>通知书</p>
        </div>
      </div>
      <div
        class="details_text"
        v-if="anyCollectionDetail.goods.is_hang"
        @click="divination"
      >
        卜卦
      </div>
      <div
        class="details_text"
        style="color: #00e3ab"
        v-if="anyCollectionDetail.goods.isPlaytoKill === 1"
        @click="getPlaytoKillPostion(anyCollectionDetail.id)"
      >
        使用
      </div>
      <div class="details_text2">
        <img
          src="../../assets/images/aipic/discords.png"
          class="details_discord"
          @click="goAipicList"
          v-if="anyCollectionDetail.goods.ai_url"
        />
      </div>
      <div class="details_text1">
        <div
          class="details_b"
          @click="playAudio(anyCollectionDetail.goods.voice)"
          v-if="anyCollectionDetail.goods.voice"
        >
          <div>
            <img
              v-if="!isPlay"
              style="width: 15px; background: none"
              src="../../assets/images/audio.png"
              alt=""
            />
            <img
              v-else
              style="width: 15px; background: none"
              src="../../assets/images/audio.gif"
              alt=""
            />
          </div>
        </div>
        <div
          class="details_a"
          @click="preview"
          v-if="anyCollectionDetail.goods.three_d_url"
        >
          3D预览
        </div>
        <div
          class="details_a details_c"
          v-if="anyCollectionDetail.goods.content"
          @click="
            $router.push(
              `/collectionDetail?content=${anyCollectionDetail.goods.content}`
            )
          "
        >
          检索门店
        </div>
        <div
          class="details_a"
          v-if="
            anyCollectionDetail.goods.goodsbaobeiList &&
            anyCollectionDetail.goods.goodsbaobeiList.length
          "
          @click="isShowPreview = true"
        >
          查看
        </div>
        <!-- <img
          src="../../assets/images/aipic/chat.png"
          class="details_chat"
          @click="goAipicChat"
          v-if="anyCollectionDetail.goods.is_user_created"
        /> -->
      </div>
    </div>

    <div class="mony">
      <div class="mony_price">购买价格</div>
      <div class="mony_sbl">￥</div>
      <div class="mony_num">{{ anyCollectionDetail.currentPrice }}</div>
    </div>

    <!-- 阴影 -->
    <div class="shadow"></div>

    <div class="message">
      <!-- <div class="message_collection">所属专辑</div> -->
      <div class="message_name">
        <div class="name_box">
          <div class="name_message">藏品名称</div>
          <div class="name_num">
            {{ anyCollectionDetail.goodsNumber }}
          </div>
        </div>
        <!-- <div class="name_box">
          <div class="name_all">全部编号</div>
          <img src="../../../static/image/right.png" alt="" />
        </div> -->
      </div>

      <div
        class="issue_box"
        v-if="
          anyCollectionDetail.belong != 5 &&
          anyCollectionDetail.goods.is_show != 0
        "
      >
        <div class="issue" v-if="anyCollectionDetail.goods.isPlaytoKill != 1">
          <span class="issue_text">发行</span>
          <span class="issue_num">
            {{ anyCollectionDetail.goods.stockNum }}份</span
          >
        </div>
        <div class="issue">
          <span class="issue_text">流通</span>
          <span class="issue_num">
            {{ anyCollectionDetail.goods.circulationNum }}份</span
          >
        </div>
      </div>

      <div class="shadow"></div>
      <div style="padding: 10px 0; max-height: 300px; box-sizing: border-box">
        <div class="craftsmanInfo">
          <div class="nickName">
            {{ craftsmanInfo.user.nickName }} <i>创作者/版权方</i>
          </div>
          <div class="address">
            <span>{{ craftsmanInfo.address }}</span>
            <span class="copy">
              <img
                src="../../../static/image/fuzhi@2x.png"
                @click="copy(craftsmanInfo.address)"
                alt=""
              />
            </span>
          </div>
        </div>
        <div class="craftsmanInfo" v-if="owner[0].user.nickName">
          <div class="nickName">
            {{ owner[0].user.nickName }}
            <i style="color: #ffaf09">拥有者</i>
          </div>
          <div class="address">
            <span>{{ owner[0].address }}</span>
            <span class="copy">
              <img
                src="../../../static/image/fuzhi@2x.png"
                @click="copy(owner[0].address)"
                alt=""
              />
            </span>
          </div>
        </div>
      </div>
      <div class="shadow"></div>

      <div
        class="anyCollectionDetailGoodsDescribe"
        v-html="anyCollectionDetail.goods.describe"
      ></div>
      <!-- <div class="tupian_img">
        <div v-for="(list, key) in this.temp" :key="key">
          <img :src="list.url" alt="" />
        </div>
      </div> -->
    </div>

    <!-- 占卜功能弹框 -->
    <van-overlay v-show="fortune">
      <div class="fortune">
        <div class="fortune_telling">
          <input type="text" v-model="divinName" placeholder="请输入卜卦姓名" />
          <div class="fortune_but">
            <div
              class="but_nn"
              @click="
                fortune = false;
                divinName = '';
              "
            >
              取消
            </div>
            <div class="but_oo" @click="divin">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay v-show="fortune1">
      <div class="fortune">
        <div class="fortune_telling">
          <input
            type="text"
            v-model="divinName1"
            placeholder="请输入学员姓名"
          />
          <div class="fortune_but">
            <div
              class="but_nn"
              @click="
                fortune1 = false;
                divinName1 = '';
              "
            >
              取消
            </div>
            <div class="but_oo" @click="divin1">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 占卜返回的图片 -->

    <van-overlay v-show="create">
      <div class="createUser">
        <div>
          <img
            :src="createUserhang.bg_img_url"
            alt=""
            @click="create = false"
          />
          <div class="close" @click="create = false"></div>
        </div>
      </div>
    </van-overlay>

    <van-overlay v-show="create1">
      <div class="createUser1">
        <div>
          <div class="shanhe">
            <img class="sh" src="../../assets/images/shanhe.jpg" alt="" />
            <div class="name">{{ createUserhang1.name }}</div>
            <div class="xueyuan">{{ createUserhang1.college }}</div>
            <div class="zhuanye">{{ createUserhang1.speciality }}</div>
            <img :src="createUserhang1.qrcode" class="qrcode" alt="" />
          </div>
          <div class="close" @click="closeshanhe"></div>
        </div>
      </div>
    </van-overlay>

    <div class="consign_box" v-if="anyCollectionDetail.is_freeze == 1">
      <div class="consign disable">
        剩余解锁时间：{{ anyCollectionDetail.unseal_day }}天
      </div>
    </div>
    <div class="consign_box" v-else>
      <!-- <div class="consign" style="background: #aaa">该商品暂无法寄卖</div> -->
      <div
        class="consign"
        :class="
          put === '该商品暂无法寄卖' ||
          put === '未到寄卖时间' ||
          anyCollectionDetail.goods.sale_chill ||
          anyCollectionDetail.goods.compose_chill
            ? 'disable'
            : put === '寄卖中，取消寄卖'
            ? 'jm'
            : null
        "
        v-text="put"
        @click="consig"
      ></div>
      <div
        v-if="anyCollectionDetail.is_set_freeze == 1"
        class="consign dongjie"
        @click="dongjie"
      >
        立即冻结
      </div>
    </div>
    <van-overlay :show="show" @click="zhe">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="mony_box">
            <span class="mony_sub"
              >￥<input
                class="input"
                @input="specifiName($event)"
                v-model="number"
                :placeholder="anyCollectionDetail.feeSection || '售价'"
                type="text" /></span
            ><span
              class="mony_none"
              @click="none"
              v-if="number && number.length > 0"
            >
              <img src="../../assets/images/x.svg" alt="" />
            </span>
          </div>
          <div class="box_tow">
            <div class="procedure">手 续 费</div>
            <div class="procedure_num">
              -￥{{ ServiceCharge.actualMoney || 0 }}
            </div>
          </div>
          <div class="box_tow">
            <div class="procedure">版 权 费</div>
            <div class="procedure_num">
              -￥{{ ServiceCharge.copyright_fee || 0 }}
            </div>
          </div>
          <div class="box_tow">
            <div class="procedure">预计到账</div>
            <div class="procedure_num procedure_text">
              ￥{{ ServiceCharge.fee || 0 }}
            </div>
          </div>
          <div class="box_tow">
            <div class="procedure">收款方式</div>
          </div>
          <div class="box_tow" style="width: 100%; box-sizing: border-box">
            <div
              class="procedure_num"
              style="width: 100%; justify-content: space-between"
            >
              <div class="group">
                <van-checkbox
                  v-model="query.isWallet"
                  icon-size="15px"
                  shape="square"
                  >华夏</van-checkbox
                >
              </div>
              <div class="group">
                <van-checkbox
                  v-model="query.isHFWallet"
                  icon-size="15px"
                  shape="square"
                  :disabled="!Boolean(h_wallet_state)"
                  >汇付</van-checkbox
                >
              </div>
              <div class="group">
                <van-checkbox
                  v-model="query.isYeePayWallet"
                  icon-size="15px"
                  shape="square"
                  >易宝钱包</van-checkbox
                >
              </div>
            </div>
          </div>

          <div class="agreement">
            <van-checkbox
              style="margin-right: 2px"
              v-model="checked"
              @click="noe"
              icon-size="15px"
            ></van-checkbox>
            已阅读并同意上述价格规则
            <span @click="Consignment">《平台寄卖协议》</span>
          </div>
          <div class="but_tow">
            <div class="but_none" @click="show = false">取消</div>
            <div class="but_confirm" @click="confirm">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 支付密码弹框 -->
    <van-overlay :show="mai">
      <div class="wap">
        <div class="aaa">
          <div class="wap_affirm">
            <div @click="die" class="cha">×</div>
            <div>请输入交易密码</div>
            <div class="forgotPwd" @click="$router.push('/forgotPwd')">
              忘记密码
            </div>
          </div>
          <div class="tips">
            <div>寄卖总金额: {{ number }}</div>
            <div>手续费: {{ ServiceCharge.actualMoney }}</div>
            <div>版权费: {{ ServiceCharge.copyright_fee }}</div>
            <div>实际到账: {{ ServiceCharge.fee }}</div>
          </div>
          <!-- 密码输入框 -->
          <van-password-input
            :value="value"
            :error-info="errorInfo"
            :gutter="10"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
            @input="specifiName($event)"
          />
          <!-- 数字键盘 -->
          <van-number-keyboard
            :show="showKeyboard"
            @input="onInput"
            @delete="onDelete"
            @blur="showKeyboard = true"
          />
        </div>
      </div>
    </van-overlay>

    <!-- 验证弹框 -->
    <van-overlay :show="showVerificationPic">
      <div class="wrapper">
        <div class="block">
          <div class="title">验证账号</div>
          <div class="info">寄卖金额大于发行价15倍，需要验证账号</div>
          <div class="userName">
            <div class="left">账号</div>
            <div class="right">{{ userName }}</div>
          </div>
          <div style="font-size: 14px; padding: 0 20px">
            <div class="verify-code">
              <van-field
                v-model="code"
                class="black_bg background"
                placeholder="请输入验证码"
              />
              <div class="verify-code_right">
                <span
                  v-if="CodeImg == ''"
                  class="white_color"
                  @click="getGraphicalCode"
                  >获取图形验证码</span
                >
                <img
                  v-else
                  style="max-width: 100%"
                  :src="CodeImg"
                  @click="getGraphicalCode"
                  alt=""
                />
              </div>
            </div>
            <div class="verify-code">
              <van-field
                v-model="verifyCode"
                maxlength="6"
                class="black_bg background"
                placeholder="请输入验证码"
              />
              <div class="verify-code_right">
                <!-- <span class="white_color" @click="codetxt1=='获取验证码'?getVerification():''">{{codetxt1}}</span> -->
                <span
                  class="white_color"
                  @click="codetxt == '获取验证码' ? getVerification() : ''"
                  >{{ codetxt }}</span
                >

                <!-- <span class="white_color">获取验证码</span> -->
              </div>
            </div>
          </div>
          <!-- <div class="info">
            *寄卖金额大于发行价15倍时需要验证是否为本人操作
          </div> -->
          <div class="but_tow">
            <div class="but_none" @click="showVerificationPic = false">
              取消
            </div>
            <div class="but_confirm" @click="verificationConfirm">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="mystery">
      <div class="mystery_nei">
        <div class="mystery_block">
          <div class="mystery_text">寄卖功能需设置交易密码,是否前往设置?</div>
          <div class="mystery_but">
            <div class="mystery_one" @click="mystery = false">取消</div>
            <div class="mystery_tow" @click="$router.push('/Deal')">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="isshowPlaytoKillPostion">
      <div class="playtoKillPostion">
        <div class="img">
          <img :src="playtoKillPostionUrl" alt="" />
          <i class="close" @click="isshowPlaytoKillPostion = false"></i>
        </div>
      </div>
    </van-overlay>

    <div class="preview" v-if="isShowPreview">
      <div class="content">
        <!-- <img :src="listPic" alt="" /> -->
        <van-swipe class="my-swipe" indicator-color="white" width="250">
          <van-swipe-item
            v-for="(item, index) in anyCollectionDetail.goods.goodsbaobeiList"
            :key="index"
          >
            <img :src="item.pic" alt="" />
          </van-swipe-item>
        </van-swipe>
        <div class="close" @click="isShowPreview = false"></div>
      </div>
    </div>
    <van-overlay :show="gotoAppShow">
      <div class="">
        <div class="chat-block">
          <div>请使用APP与TA聊天</div>
          <div class="chat-over_box">
            <div class="chat-over_none" @click="gotoAppShow = false">取消</div>
            <div class="chat-over_en" @click="openApp">去下载app</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showTip3">
      <div class="">
        <div class="chat-block">
          <div style="width: 80%; margin: 0 auto">{{ tip3 }}</div>
          <div class="chat-over_box">
            <div class="chat-over_none" @click="showTip3 = false">取消</div>
            <div class="chat-over_en" @click="submit">继续</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-action-sheet
      v-model="showDongjie"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      description="请选择锁定时间"
      @select="selectDay"
    />

    <div class="tips1" v-if="isShowTip">
      <div class="box">
        <div class="title">锁定操作确认</div>
        <div class="info">
          本人确认将该藏品锁定{{ suodingDay }}天，锁定期间
          藏品不可进行任何操作，时间到期后将 自动解锁，此操作一旦确认将不可修改,
          请谨慎选择！
        </div>
        <div class="btn">
          <div class="left" @click="isShowTip = false">取消</div>
          <div class="right" @click="userSetPostionFreeze">确定</div>
        </div>
      </div>
    </div>

    <div class="tips1" v-if="isShowTip1">
      <div class="box">
        <div class="title">锁定成功</div>
        <div class="info">藏品锁定成功！</div>
        <div class="btn">
          <div
            class="right"
            style="margin: 0 auto"
            @click="
              isShowTip1 = false;
              $router.back();
            "
          >
            确定
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  name: "Collection",
  data() {
    return {
      showDongjie: false,
      actions: [],
      showTip3: false,
      isShowTip1: false,
      tip3: "",
      isshowPlaytoKillPostion: false,
      isShowPreview: false,
      playtoKillPostionUrl: "",
      initLoading: true,
      mystery: false,
      temp: "",
      code: "", // 图形验证码
      CodeImg: "", // 图片验证码
      emailCode: "", // 图片验证码  邮箱
      showVerificationPic: false,
      bgImgUrl: "",
      checked: "", //选择平台寄卖协议
      codetxt: "获取验证码",
      fortune: false, // 卜卦
      id: "",
      createUserhang1: "",
      anyCollectionDetail: { goods: {} }, //我的藏品详情
      ConsignSell: "",
      show: false,
      create1: false,
      fortune1: false,
      divinName1: "",
      numid: "",
      number: "", //实时金额
      verifyCode: "",
      num: "", //购买金额
      ServiceCharge: {},
      put: "立即寄卖",
      sta: "", //状态
      hang: "", //占卜状态
      divinName: "", //占卜人名
      createUserhang: "",
      create: false, //卜卦结果
      checkedId: " ", // 寄卖协议是否同意
      tupian: " ", // 图片
      mai: false, // 密码弹框
      value: "", //密码输入框
      errorInfo: "",
      userName: "",
      showKeyboard: true,
      threeDurl: "",
      craftsmanInfo: { user: {} },
      owner: [{ user: {} }],
      loginType: 1,
      isOpen: false,
      isPayPassword: false,
      audio: null,
      isPlay: false,
      isPlayVideo: false,
      gotoAppShow: false,
      isShowTip: false,
      query: {
        isWallet: true,
        isHFWallet: false,
        isYeePayWallet: false,
      },
      h_wallet_state: 0,
      suodingDay: null,
      yeePayWalletState: 0,
      showYiBao: false,
    };
  },
  computed: {
    findOwner() {
      if (this.isOpen) {
        return this.owner;
      } else {
        return [this.owner[0]];
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.cb);
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
      this.isPlay = false;
    }
  },
  mounted() {
    document.addEventListener("visibilitychange", this.cb);
    if (!localStorage.getItem("token")) return this.$router.push("/login");
    this.initLoading = true;
    this.loginType = localStorage.getItem("loginType");
    this.$api.Userinfo({ token: localStorage.getItem("token") }).then((res) => {
      if (res.code === 0) {
        this.h_wallet_state = res.data.h_wallet_state;
        this.yeePayWalletState = res.data.yeePayWalletState;
        if (res.data.is_pay_password == 1) {
          this.isPayPassword = true;
        } else {
          this.isPayPassword = false;
        }
        if (this.loginType == "2") {
          // 邮箱
          this.userName = res.data.email;
        } else {
          // 手机号
          this.userName = res.data.contactTel;
        }
      }
    });

    const loginType = localStorage.getItem("loginType");
    if (loginType == "1") {
      // 手机号
    } else {
      // 邮箱
    }
    this.getDetail();

    this.$api.anyRuleInfo({ type: "is_yeepay_open" }).then((e) => {
      if (e.data.rule == 1) {
        //
        this.showYiBao = true;
      }
    });
  },
  watch: {
    show(val) {
      if (!val) {
        this.none();
        this.checked = false;
        this.checkedId = 0;
        if (this.timer) {
          this.codetxt = "获取验证码";
          clearInterval(this.timer);
        }
      }
    },
    "query.isYeePayWallet"(val) {
      if (val && !this.showYiBao) {
        this.query.isYeePayWallet = false;
        return this.$toast("敬请期待～");
      }
      if (val && !this.yeePayWalletState) {
        this.query.isYeePayWallet = false;
        Dialog.confirm({
          title: "提示",
          message: "是否前往开通易宝钱包",
          confirmButtonText: "去开通",
        }).then(() => {
          // on confirm
          this.$router.push("/yibao");
        });
      }
    },
  },
  methods: {
    getDetail() {
      this.id = this.$route.query.id;
      let params = {
        token: localStorage.getItem("token"),
        id: this.id,
      };
      this.$api.anyCollectionDetail(params).then(
        (res) => {
          this.initLoading = false;
          if (res.code == 0) {
            this.anyCollectionDetail = res.data;
            this.num = res.data.currentPrice;
            this.id = res.data.goodsId;
            this.idnum = res.data.id;
            this.hang = res.data.goods.is_hang; // 是否可以占卜
            this.threeDurl = res.data.goods.three_d_url;
            this.sta = res.data.status; //状态
            this.tupian = res.data.goods.describe;
            this.getAnyGoodsUserhaveList(this.anyCollectionDetail.id);

            this.audio = new Audio();
            this.audio.addEventListener(
              "ended",
              () => {
                this.isPlay = false;
              },
              false
            );
            this.audio.src = this.anyCollectionDetail.goods.voice;
            let a = this.tupian;
            var b = /<img[^>]+src=['"]([^'"]+)['"]+/g;
            let result = [];
            let temp = [];
            while ((temp = b.exec(a)) != null) {
              result.push({ url: temp[1] });
            }
            this.temp = result;
            // this.$router.go(0);
            if (this.sta == 0) {
              this.put = "立即寄卖";
            } else if (this.sta == 3) {
              this.put = "寄卖中，取消寄卖";
            } else if (this.sta == 4) {
              this.put = "已经出售";
            }
            if (!res.data.goods.isDeal) {
              this.put = "该商品暂无法寄卖";
            } else if (!res.data.goods.is_allow) {
              this.put = "未到寄卖时间";
            } else if (res.data.goods.sale_chill) {
              this.put = res.data.goods.sale_chill;
            } else if (res.data.goods.compose_chill) {
              this.put = res.data.goods.compose_chill;
            }
            if (res.data.goods.is_dawn) {
              this.put = "进入游戏";
            }
          }
        },
        () => {
          this.initLoading = false;
        }
      );
    },
    closeshanhe() {
      this.getDetail();
      this.create1 = false;
    },
    showFortune() {
      if (this.anyCollectionDetail.chartInfo) {
        this.createUserhang1 = JSON.parse(this.anyCollectionDetail.chartInfo);
        this.create1 = true;
      } else {
        this.fortune1 = true;
      }
    },
    selectDay(e) {
      this.isShowTip = true;
      this.suodingDay = e.name;
    },
    userSetPostionFreeze() {
      const query = {
        postionId: this.anyCollectionDetail.id,
        token: localStorage.getItem("token"),
        day: this.suodingDay,
      };
      this.$api.userSetPostionFreeze(query).then((res) => {
        if (res.code == 0) {
          this.isShowTip1 = true;
        } else {
          return this.$toast(res.msg);
        }
      });
    },
    dongjie() {
      this.$api
        .FreezeConfig({
          goodsId: this.anyCollectionDetail.goodsId,
          token: localStorage.getItem("token"),
        })
        .then((e) => {
          if (e.code == 0) {
            this.actions = e.data.map((v) => {
              return {
                name: v.day,
                id: v.id,
              };
            });
            this.showDongjie = true;
          } else {
            return this.$toast(e.msg);
          }
        });
    },
    getPlaytoKillPostion(id) {
      this.$api
        .playtoKillPostion({ token: localStorage.getItem("token"), id })
        .then((e) => {
          if (e.code === 0) {
            this.isshowPlaytoKillPostion = true;
            this.playtoKillPostionUrl = e.url;
          } else {
            return this.$toast(e.msg);
          }
        });
    },
    cb() {
      if (document.hidden) {
        if (this.audio) {
          this.audio.pause();
          this.isPlay = false;
        }
      }
    },
    playVideo() {
      this.$refs.video.play();
      this.isPlayVideo = true;
    },
    playAudio() {
      if (this.isPlay) {
        this.audio.pause();
      } else {
        this.audio.play();
      }
      this.isPlay = !this.isPlay;
    },
    verificationConfirm() {
      if (!this.verifyCode) return this.$toast("请输入短信验证码");
      this.showVerificationPic = false;
      this.mai = true;
    },
    // 获取图片验证码
    getGraphicalCode() {
      if (this.loginType == "2") {
        // 邮箱
        this.$api
          .getEmailGraphicalCode({ email: this.userName })
          .then((res) => {
            this.$toast.success("获取成功");
            this.CodeImg = (URL || webkitURL).createObjectURL(
              new Blob([res], { type: "img/jpeg" })
            );
          });
      } else {
        // 手机号
        this.$api.getGraphicalCode({ tel: this.userName }).then((res) => {
          this.$toast.success("获取成功");
          this.CodeImg = (URL || webkitURL).createObjectURL(
            new Blob([res], { type: "img/jpeg" })
          );
        });
      }
    },
    // 获取商品拥有者
    getAnyGoodsUserhaveList(id) {
      const params = {
        id,
        type: 1,
      };
      this.$api.anyGoodsUserhaveList(params).then((res) => {
        if (res.code == 0) {
          this.craftsmanInfo = res.craftsmanInfo || { user: {} };
          this.owner = (res.data || []).filter(
            (e) => e.user.id !== this.craftsmanInfo.user.id
          );
          if (!this.owner.length) {
            this.owner = [{ user: {} }];
          }
          console.log(this.owner);
        }
      });
    },
    // 3D预览
    preview() {
      let id = this.threeDurl;
      this.$router.push({ path: "/three", query: { id: id } });
    },

    die() {
      this.mai = false;
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },

    // specifiName() {},
    onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        let params = {
          token: localStorage.getItem("token"),
          id: this.idnum,
          fee: this.number,
          agreementid: this.checkedId,
          payPassword: this.value,
          checkCode: this.verifyCode,
          isWallet: Number(this.query.isWallet),
          isHFWallet: Number(this.query.isHFWallet),
          isYeePayWallet: Number(this.query.isYeePayWallet),
        };

        this.$api.ConsignmentSell(params).then((res) => {
          if (res.code == 0) {
            this.$toast("寄卖成功");
            this.show = false;
            this.$router.push("/Allorder");
          } else {
            this.value = "";
            this.$toast(res.msg);
          }
        });
      }
    },
    // 平台寄卖协议
    noe() {
      if (this.checked == true) {
        this.checkedId = 2;
      } else {
        this.$toast("请先同意《平台寄卖协议》");
        this.checkedId = 0;
      }
    },
    Consignment() {
      this.$router.push("/add");
    },
    // 卜卦、
    divination() {
      this.getUserhang();
    },
    getUserhang() {
      let params = {
        token: localStorage.getItem("token"),
        id: this.idnum,
      };
      this.$api.getUserhang(params).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.createUserhang = res.data;
            this.create = true;
            this.fortune = false;
          } else {
            this.fortune = true;
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    divin1() {
      const query = {
        positionid: this.idnum,
        token: localStorage.getItem("token"),
        name: this.divinName1,
      };
      this.$api.getUserSpeciality(query).then((res) => {
        if (res.code == 0) {
          this.createUserhang1 = res.data;
          this.create1 = true;
          this.fortune1 = false;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    divin() {
      let params = {
        token: localStorage.getItem("token"),
        id: this.idnum,
        name: this.divinName + "的卦象",
      };

      this.$api.createUserhang(params).then((res) => {
        if (res.code == 0) {
          this.createUserhang = res.data;
          this.create = true;
          this.fortune = false;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    async isLessOne() {
      return Dialog.confirm({
        title: "温馨提示",
        message: "该笔订单实际到账金额小于1元，是否继续提交寄卖？",
      });
    },
    // 确认寄卖
    async confirm() {
      this.code = "";
      this.verifyCode = "";
      this.CodeImg = "";
      this.value = "";
      if (this.checkedId == " ") {
        this.$toast("请先同意《平台寄卖协议》");
        return;
      }

      if (!this.number) {
        this.$toast("请输入寄卖金额");
        return;
      }
      if (
        !this.query.isWallet &&
        !this.query.isHFWallet &&
        !this.query.isYeePayWallet
      ) {
        this.$toast("请选择收款方式");
        return;
      }
      // if (this.ServiceCharge.fee <= 1) {
      //   try {
      //     await this.isLessOne();
      //   } catch (error) {
      //     console.log(error);
      //     return;
      //   }
      // }
      if (!this.userName || !localStorage.getItem("token")) {
        this.$toast("登录状态异常，请重新登录");
        setTimeout(() => {
          let params = {
            token: localStorage.getItem("token"),
          };
          this.$api.logout(params).then((res) => {
            if (res.code == 0) {
              // this.$router.push('/login');
              localStorage.removeItem("token");
              window.location.reload();
            }
          });
        }, 1000);
        return;
      }
      // if (this.number > this.anyCollectionDetail.goods.applyPrice * 15) {
      //   this.showVerificationPic = true;
      //   return;
      // }

      const query1 = {
        token: localStorage.getItem("token"),
        id: this.$route.query.id,
        money: this.number,
      };
      this.$api.lowPrice(query1).then((v) => {
        // if (v.msg) this.$toast(v.msg);
        if (v.state == 1) {
          this.tip3 = v.msg;
          this.showTip3 = true;
        } else {
          this.submit();
        }
      });
    },
    submit() {
      this.showTip3 = false;
      const query = {
        token: localStorage.getItem("token"),
        id: this.anyCollectionDetail.goodsId,
        fee: this.number,
      };
      this.$api.getShopIsAlowBuy(query).then((e) => {
        if (e.code === 0) {
          this.mai = true;
        } else if (e.code === 1002) {
          Dialog.confirm({
            title: "标题",
            message: e.msg,
            cancelButtonText: "取消",
            confirmButtonText: "继续寄卖",
          }).then(() => {
            this.mai = true;
          });
          return;
        } else {
          return this.$toast(e.msg);
        }
      });
    },
    // 获取验证码
    getVerification() {
      if (!this.code) return this.$toast("请输入图形验证码");
      let toastEml = Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      // 当前输入手机号
      let params = {
        tel: this.userName,
        email: this.userName,
        code: this.code,
      };
      let fun = this.$api.anyCheckcodeforget;
      if (this.loginType != "1") {
        fun = this.$api.anyCheckcodeforgetForEmail;
      }
      fun(params).then((res) => {
        if (res.code == 0) {
          // 调用记载中提示
          toastEml.clear();
          Toast.success({
            message: "验证码已发送",
            forbidClick: true,
          });
          let n = 60;
          this.timer = setInterval(() => {
            this.codetxt = `${n--}s`;
            if (n == 0) {
              this.codetxt = "获取验证码";
              clearInterval(this.timer);
            }
          }, 1000);
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        } else {
          this.$toast.fail("获取失败");
        }
      });
    },

    // 实时监听
    specifiName(e) {
      const val = e.target.value;
      let res;
      if (/[\.]/.test(val)) {
        res = val.match(/^\d{0,8}(\.?\d{0,2})/g)[0] || "";
      } else {
        res = val.match(/^\d{0,8}/g)[0] || "";
      }
      if (!this.number) {
        this.none();
        return this.$toast("请输入数字");
      }
      this.$nextTick(() => {
        this.number = res;
        let params = {
          token: localStorage.getItem("token"),
          id: this.id,
          money: this.number,
        };
        this.$api.ServiceCharge(params).then((res) => {
          if (res.code == 100000) {
            this.ServiceCharge = res.data;
          }
        });
      });
    },
    none() {
      this.number = undefined;
      this.ServiceCharge = {};
      // this.show = false;
    },
    // 立即寄卖
    async consig() {
      if (this.put == "进入游戏") {
        const e = await this.$api.runDawnGame({
          token: localStorage.getItem("token"),
          positionId: this.anyCollectionDetail.id,
        });
        switch (Number(e.data.round)) {
          case 1:
            this.$router.push(
              `/tianliangle?id=${
                this.anyCollectionDetail.id
              }&token=${localStorage.getItem("token")}`
            );
            return;
          case 2:
            this.$router.push(
              `/age?id=${
                this.anyCollectionDetail.id
              }&token=${localStorage.getItem("token")}`
            );
            return;
          case 3:
            this.$router.push(
              `/clues?id=${
                this.anyCollectionDetail.id
              }&token=${localStorage.getItem("token")}`
            );
            return;

          default:
            return;
        }
      }
      if (
        this.put == "该商品暂无法寄卖" ||
        this.put == "未到寄卖时间" ||
        this.anyCollectionDetail.goods.sale_chill ||
        this.anyCollectionDetail.goods.compose_chill
      ) {
        return false;
      }
      if (this.put == "寄卖中，取消寄卖") {
        Dialog.confirm({
          title: "提示",
          message: "是否确认取消寄卖？",
        })
          .then(() => {
            // 确认
            let params = {
              id: this.anyCollectionDetail.entrust_id,
              token: localStorage.getItem("token"),
            };

            this.$api.anyCancel(params).then((res) => {
              if (res.code == 0) {
                this.$toast(res.msg);
                this.$router.back();
                return;
              } else {
                return this.$toast(res.msg);
              }
            });
          })
          .catch(() => {
            // 取消
          });
        return;
      }
      if (this.anyCollectionDetail.goods.condition) {
        return this.$toast(this.anyCollectionDetail.goods.condition);
      }
      if (!this.isPayPassword) {
        this.mystery = true;
        return false;
      }
      if (this.sta == 4) {
        return this.$toast("商品已经出售");
      }

      const res = await this.$api.userBehaviorState({
        token: localStorage.getItem("token"),
      });
      if (res.isBlacklist == 1) return this.$toast(res.msg);

      this.show = true;
    },

    zhe() {
      this.show = false;
    },
    // 返回上一页
    back() {
      this.$router.push("/user");
    },
    copy(url) {
      //新建一个文本框
      let oInput = document.createElement("input");
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove();
      this.$toast("复制成功");
    },
    goAipicChat() {
      if (this.$route.query.platform) {
        this.$router.push(
          `/aipicChat?name=${this.anyCollectionDetail.goods.name}&url=${this.anyCollectionDetail.goods.showPic}&userUrl=${this.craftsmanInfo.user.headImg}`
        );
      } else {
        this.gotoAppShow = true;
      }
    },
    goAipicList() {
      this.id = this.$route.query.id;
      if (this.$route.query.platform) {
        this.$router.push(
          `/dcpiclist?id=` + this.id + "&token=" + localStorage.getItem("token")
        );
      } else {
        this.gotoAppShow = true;
      }
    },
    openApp() {
      window.open("https://v.hxnft.shop/appDownload/index.html");
      this.showBox = false;
    },
  },
};
</script>

<style lang="less" scoped>
// .wap_affirm {
//   display: flex;
//   // margin-top: 300px;
//   align-items: center;
//   justify-content: space-between;
//   font-size: 20px;
//   padding-bottom: 10px;
//   padding-top: 10px;
//   padding-left: 10px;
// }
.aaa {
  background-color: #ffffff;
  // padding-bottom: 500px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
// van-password-input__item--focus"
/deep/.van-password-input__item {
  border: 2px solid #4c4b58;
  border-radius: 5px;
  box-sizing: border-box;
}
/deep/.van-password-input {
  margin-bottom: 20px;
}
/deep/.van-number-keyboard {
  position: relative;
}
.tupian_img {
  width: 95%;
  img {
    width: 100%;
  }
}
.createUser {
  width: 70%;
  height: 60%;
  display: flex;
  align-items: center;
  margin-left: 55px;
  margin-top: 100px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
  .close {
    display: inline-block;
    margin-top: 50px;
    margin: 0 auto;

    //改叉叉大小 6：1等量放大缩小就行（算好的，省的自己调整太麻烦）
    width: 20px;
    height: 2px;

    background: #fff;
    line-height: 0;
    font-size: 0;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
    border-radius: 50%;
  }

  .close:after {
    content: "/";
    display: block;

    //改叉叉大小 6：1等量放大缩小就行（这里也要改）
    width: 20px;
    height: 2px;
    visibility: initial;
    background: #fff;
    -webkit-transform: rotate(-90deg);
  }
}
.fortune {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  .fortune_telling {
    border-radius: 5px;
    width: 60%;
    height: 120px;
    background-color: #fff;
    input {
      text-align: center;
      font-size: 16px;
    }
  }
  .fortune_but {
    font-size: 16px;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    .but_nn {
      width: 60px;
      line-height: 20px;
      text-align: center;
      height: 20px;
      border: 2px solid #999999;
    }
    .but_oo {
      width: 60px;
      line-height: 20px;
      text-align: center;
      height: 20px;
      border: 2px solid black;
      background-color: black;
      color: #ffffff;
      margin-left: 20px;
    }
  }
  input {
    width: 90%;
    border: 0;
    margin-top: 20px;
    margin-left: 10px;
  }
}
.but_confirm {
  width: 74px;
  height: 27px;
  border: 1px solid #cecece;
  text-align: center;
  line-height: 27px;
  color: #ffffff;
  background-color: #0754d3;
}
.but_none {
  width: 74px;
  height: 27px;
  border: 1px solid #cecece;
  text-align: center;
  line-height: 27px;
  color: #0754d3;
}
.but_tow {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
  margin-top: 17px;
  margin-bottom: 20px;
}
.agreement {
  font-size: 12px;
  color: #999999;
  padding: 0 20px;
  display: flex;
  align-items: center;
  span {
    color: cornflowerblue;
  }
}
.box_tow {
  display: flex;
  justify-content: space-between;
  padding-left: 21px;
  padding-right: 21px;
  height: 40px;
  line-height: 40px;
  .procedure {
    font-size: 15px;
    color: #999999;
  }
  .procedure_num {
    color: red;
    white-space: nowrap;
    font-size: 15px;
    display: flex;
    align-items: center;
    .group {
      display: flex;
      align-items: center;
      span {
        color: #666;
        margin-left: 5px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .procedure_text {
    color: blue;
  }
}
.mony_box {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding-left: 17px;
  padding-right: 17px;
  height: 50px;
  line-height: 50px;
  .mony_sub {
    width: 100%;
    display: flex;
    input {
      width: auto;
      flex: 1;
    }
  }
  .mony_none {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 18px;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  background-color: #fff;
  // width: 234px;
  border-radius: 5px;
  .title {
    font-size: 20px;
    margin: 20px auto 0;
    text-align: center;
  }
  .userName {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 14px;
  }
  .info {
    font-size: 13px;
    color: red;
    padding: 0 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    text-align: center;
    width: 70%;
    margin: 10px auto;
  }
}

.consign_box {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: ghostwhite;
  display: flex;
  padding-top: 5px;
  justify-content: center;
  padding-bottom: 5px;
  padding: 5px 20px;
  box-sizing: border-box;
}
.consign {
  background-color: #0754d3;
  color: #ffffff;
  // width: 261px;
  height: 40px;
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  line-height: 40px;
  flex: 1;
  &:nth-child(2) {
    margin-left: 20px;
  }
  &.dongjie {
    background: #ffaf09;
  }
  &.disable {
    background-color: #aaa;
  }
  &.jm {
    background-color: #fffbf2;
    color: #ffaf09;
  }
}
.mony {
  display: flex;
  padding: 10px 0;
  align-items: center;
  .mony_price {
    color: #999999;
    font-size: 16px;
    margin-left: 17px;
  }
  .mony_sbl {
    font-size: 10px;
    margin-left: 10px;
    margin-right: 5px;
  }
  .mony_num {
    font-size: 20px;
  }
}
.input {
  border: 0;
  width: 50%;
}

// 阴影
.shadow {
  background-color: #f1f1f1;
  height: 10px;
}
.is_allow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    background-color: transparent !important;
    width: 80px !important;
  }
  div {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #d9d9d9;
    text-align: center;
  }
}
.videos {
  position: relative;
  img.bg {
    position: relative;
    z-index: 9;
  }
  .play {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    img {
      width: 30px;
      background: transparent;
      margin-left: 5px;
    }
  }
  video {
    width: 100%;
    vertical-align: middle;
    background-color: black;
    z-index: 10;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    object-fit: cover;
  }
}
/deep/.van-overlay {
  z-index: 99;
}
.details_box {
  width: 100vw;
  position: relative;
  img {
    width: 100%;
    // height: 254px;
    vertical-align: middle;
    background-color: lightblue;
    display: inline-block;
  }
  video {
    width: 100%;
    vertical-align: middle;
    background-color: black;
  }
  .details_text {
    position: absolute;
    bottom: 20px;
    left: 10px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    width: 50px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
  }
  .details_b {
    height: 25px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    width: 50px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .details_text1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 10px;
    display: flex;
  }
  .details_text2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 10px;
    display: flex;
  }

  .details_a {
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    width: 50px;
    background-color: rgba(0, 0, 0, 0.4);
    margin-left: 10px;
    border-radius: 20px;
    &.details_c {
      width: auto;
      padding: 0 10px;
    }
  }
}
.issue_box {
  display: flex;
  padding: 0 15px;
  .issue {
    .issue_text {
      background-color: #abaab9;
      font-size: 12px;
      color: #ffffff;
      display: inline-block;
      height: 20px;
      width: 34px;
      text-align: center;
      line-height: 20px;
    }

    padding-bottom: 25px;
    .issue_num {
      font-size: 12px;
      line-height: 20px;
      height: 20px;
      display: inline-block;
      background-color: #f3f4f8;
      padding-left: 5px;
      padding-right: 5px;
      color: #4c4b58;
      margin-right: 10px;
    }
  }
}
.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  background-color: #ffffff;
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    width: 90%;
    font-size: 18px;
    text-align: center;
  }
}

.message {
  // margin-left: 16px;
}
.message_collection {
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 15px;
}
.message_name {
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
  .name_box {
    display: flex;
    align-items: center;
    padding-top: 10px;
    .name_message {
      font-size: 16px;
      line-height: 50px;
      white-space: nowrap;
    }
    .name_num {
      font-size: 18px;
      color: black;
      font-weight: 600;
      margin-left: 10px;
    }
    .name_all {
      font-size: 14px;
    }
    img {
      width: 6px;
      height: 10px;
      display: inline-block;
      margin-right: 16px;
      margin-left: 7px;
    }
  }
}
.craftsmanInfo {
  padding: 10px 15px;
  position: relative;
  .open {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #aaa;
    border-right: 2px solid #aaa;
    transition: all 0.3s;
  }
  .openImg {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    padding: 0;
  }
  .nickName {
    font-size: 12px;
    font-weight: 600;
    i {
      font-style: italic;
      color: #0754d3;
      margin-left: 7px;
    }
  }
  .address {
    font-size: 12px;
    color: #abaab9;
    display: flex;
    align-items: center;
    &.between {
      justify-content: space-between;
      span {
        text-align: right;
        font-size: 12px;
      }
    }
    span {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.between {
        text-align: right;
      }
    }
    .copy {
      width: 13px;
      display: block;
      margin-left: 10px;
      img {
        width: 100%;
      }
    }
  }
}
.wap_affirm {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  div {
    flex: 2;
    text-align: center;
    font-size: 16px;
  }
  .cha {
    flex: 1;
    color: #666666;
    text-align: left;
    font-size: 30px;
  }
  .forgotPwd {
    flex: 1;
    text-align: right;
    font-size: 14px;
    color: rgb(rgb(7 84 211));
  }
}
.verify-code {
  display: flex;
  border-bottom: 1px solid #ddd;
  align-items: center;
  padding: 5px 0;
  .verify-code_right {
    display: flex;
    width: 48%;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: rgb(rgb(7 84 211));
      white-space: nowrap;
    }
    span::before {
      content: " ";
      display: flex;
      width: 1px;
      height: 30px;
      margin-right: 10px;
      background: #eeeeee;
    }
  }
}
/deep/.van-cell {
  padding-left: 0;
}
.tips {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 30px;
  div {
    width: 50%;
    font-size: 14px;
    margin: 5px 0;
  }
}
.mystery_nei {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .mystery_block {
    background-color: #fff;
    width: 250px;
    height: 150px;
    border-radius: 10px;
    .mystery_text {
      font-size: 16px;
      text-align: center;
      width: 80%;
      margin: 20px auto 0;
    }
    .mystery_but {
      display: flex;
      font-size: 14px;
      justify-content: space-around;
      margin-top: 20px;
      .mystery_tow {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        background-color: black;
        color: #ffffff;
      }
      .mystery_one {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        // background-color: ;
        border: 1px black solid;
      }
    }
  }
}
.playtoKillPostion {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .img {
    text-align: center;
    img {
      max-width: 80%;
    }
    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #fff;
      width: 25px;
      height: 25px;
      position: relative;
      margin: 35px auto 0;
      transform: rotate(45deg);
      &::before {
        display: block;
        content: "";
        width: 70%;
        height: 1px;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &::after {
        display: block;
        content: "";
        height: 70%;
        width: 1px;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.preview {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.4);
  .content {
    text-align: center;
    .my-swipe {
      width: 250px;
      height: 250px;
      .van-swipe-item {
        color: #fff;
        text-align: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    img {
      width: 200px;
    }
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .close {
      display: inline-block;
      margin-top: 50px;
      margin: 0 auto;

      //改叉叉大小 6：1等量放大缩小就行（算好的，省的自己调整太麻烦）
      width: 20px;
      height: 2px;

      background: #fff;
      line-height: 0;
      font-size: 0;
      vertical-align: middle;
      -webkit-transform: rotate(45deg);
      border-radius: 50%;
    }

    .close:after {
      content: "/";
      display: block;

      //改叉叉大小 6：1等量放大缩小就行（这里也要改）
      width: 20px;
      height: 2px;
      visibility: initial;
      background: #fff;
      -webkit-transform: rotate(-90deg);
    }
  }
}
.details_discord {
  position: relative;
  width: 35px !important;
  left: 10px !important;
  background-color: rgb(#fff, 0) !important;
}
.details_chat {
  position: relative;
  width: 35px !important;
  right: 10px !important;
  background-color: rgb(#fff, 0) !important;
}
.chat-block {
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  padding-top: 30px;
  width: 300px;
  margin: 0 auto;
  margin-top: 60%;
  background-color: #fff;
}
.chat-over_box {
  margin-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;
}

.chat-over_none {
  color: #0754d3;
  width: 74px;
  text-align: center;
  line-height: 25px;
  height: 25px;
  border-radius: 5px;
  border: 1px #cecece solid;
}

.chat-over_en {
  color: #ffffff;
  width: 74px;
  text-align: center;
  background-color: #0754d3;
  line-height: 25px;
  height: 25px;
  border-radius: 5px;
  border: 1px #0754d3 solid;
}
.tips1 {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    padding: 20px;
    box-sizing: border-box;
    width: 282px;
    // height: 170px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 15px;
      text-align: center;
      font-weight: 600;
      color: #000;
    }
    .info {
      font-size: 14px;
      margin: 20px 0 25px;
      color: #666;
      text-align: center;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 80px;
        height: 28px;
        background: #0754d3;
        border-radius: 3px;
        border: 1px solid #0754d3;
        color: #fff;
        &.left {
          color: #0754d3;
          border: 1px solid #0754d3;
          background: transparent;
        }
      }
    }
  }
}
.createUser1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  text-align: center;
  .shanhe {
    width: 360px;
    position: relative;
    img.sh {
      width: 100%;
    }
    .qrcode {
      width: 50px;
      position: absolute;
      left: 50%;
      top: 195px;
      transform: translateX(-50%);
      margin-left: -20px;
    }
    .name {
      font-size: 14px;
      color: #000;
      position: absolute;
      right: 270px;
      top: 107px;
    }
    .xueyuan {
      font-size: 14px;
      color: #000;
      position: absolute;
      left: 80px;
      top: 148px;
    }
    .zhuanye {
      font-size: 14px;
      color: #000;
      position: absolute;
      left: 50%;
      top: 148px;
      transform: translateX(-50%);
    }
  }
  .close {
    display: inline-block;
    margin-top: 50px;
    margin: 0 auto;

    //改叉叉大小 6：1等量放大缩小就行（算好的，省的自己调整太麻烦）
    width: 20px;
    height: 2px;

    background: #fff;
    line-height: 0;
    font-size: 0;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
    border-radius: 50%;
  }
  .close:after {
    content: "/";
    display: block;

    //改叉叉大小 6：1等量放大缩小就行（这里也要改）
    width: 20px;
    height: 2px;
    visibility: initial;
    background: #fff;
    -webkit-transform: rotate(-90deg);
  }
}
</style>
<style>
.anyCollectionDetailGoodsDescribe img {
  max-width: 100%;
}
.van-checkbox__label {
  margin-left: 5px !important;
}
</style>
